/* eslint-disable max-lines-per-function */
import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { IOrderOption } from '@/modules/order-type/types';
import { RouteName } from '../../../common/routes/routeName';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { t } = useI18n();
  const router = useRouter();
  const metadataStore = useMetadataStore();
  const { triggerAddTableNumber, triggerSelectOrderType } = useGTMEventsComposableV2();
  // State
  const tableNumber = ref<string>('');
  const orderTypes = ref<IOrderOption[]>([]);
  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  // Getters
  const isPickupOrder = computed(() => fulfillmentMode.value === FulfillmentMode.PICKUP);

  const orderType = computed(() => {
    if (fulfillmentMode.value === FulfillmentMode.PICKUP) {
      return t('TAKE_AWAY');
    }

    return tableNumber.value ? `${t('EAT_HERE')} - ${t('TABLE')} ${tableNumber.value}` : t('EAT_HERE');
  });

  const orderOptions = computed(() => orderTypes.value.map((type) => ({
    ...type,
    label: t(type.label),
  })));

  // Actions
  function setOrderOptions(value: IOrderOption[]) {
    orderTypes.value = value;
  }

  function setTableNumber(value: string) {
    tableNumber.value = value.toString();
    // Trigger GTM event
    if (value.length > 0) triggerAddTableNumber(value);
  }

  function setOrderTypeValue(_orderType: IOrderOption, hideTableNumber: boolean) {
    if (!metadataStore.brandSettings) return;
    fulfillmentMode.value = _orderType.value;
    // Trigger GTM event
    triggerSelectOrderType(_orderType.value);

    const redirectTo = metadataStore.hideCategories ? RouteName.PRODUCTS : RouteName.ORDER_CATEGORIES;
    if (isPickupOrder.value) {
      setTableNumber('');
      router.push({ name: redirectTo });
    } else {
      const newRoute = hideTableNumber ? redirectTo : RouteName.ORDER_TABLE;
      router.push({ name: newRoute });
    }
  }

  return {
    orderTypes,
    orderOptions,
    orderType,
    tableNumber,
    fulfillmentMode,
    isPickupOrder,
    setOrderOptions,
    setOrderTypeValue,
    setTableNumber,
  };
});
