<script lang="ts" setup>
import { HubsterCategory } from '@slabcode/hubster-models/hubster/common';
import { HubsterMenuPublishItem } from '@slabcode/hubster-models/hubster/payloads/menu/publish/menuData/item';
import { PropType } from 'vue';
import { useScrollableReset } from '@/common/composables';

const cartStoreV2 = useCartStoreV2();
const route = useRoute();
const menuStore = useMenuStore();

const contentRef = ref<HTMLElement | null>(null);

const metadataStore = useMetadataStore();
const { alcohol } = storeToRefs(menuStore);
const showAlcoholConfirm = ref<boolean>(false);
const { priceDecimals, cardRadiusClass } = storeToRefs(metadataStore);
const { triggerSelectItem, triggerAddToCart } = useGTMEventsComposableV2();
const { startCustomization } = useCustomize();

const props = defineProps({
  category: {
    type: Object as PropType<HubsterCategory>,
    required: true,
  },
  productItems: {
    type: Array as PropType<HubsterMenuPublishItem[]>,
    required: true,
  },
});

const selectedItem = ref<HubsterMenuPublishItem>();

function setSelectedProduct(item: HubsterMenuPublishItem) {
  selectedItem.value = item;
}

function addItem(item: HubsterMenuPublishItem) {
  triggerSelectItem({
    id: item.id,
    name: item.name,
    price: item.price.amount,
    quantity: 1,
  }, props.category);

  if (item.modifierGroupIds.length === 0) {
    cartStoreV2.addItemWithoutCustomization(item.id);

    // Trigger GTM events
    triggerAddToCart({
      item: toGTMCartItem(cartStoreV2.items.at(-1)!),
      section: route.name,
      operation: CartEventType.ADD,
    });
    return;
  }

  startCustomization(item.id);
}

function denyAlcoholProducts() {
  selectedItem.value = undefined;
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ allow: false });
}

function confirmProductSelection() {
  showAlcoholConfirm.value = false;
  menuStore.setAlcoholValue({ checked: true });
  // Add item to cart
  if (selectedItem.value) addItem(selectedItem.value);
}

function openAlcoholConfirm(item: HubsterMenuPublishItem) {
  setSelectedProduct(item);

  if (alcohol.value.checked) {
    confirmProductSelection();
    return;
  }
  // Show alcohol confirm
  showAlcoholConfirm.value = true;
}

onMounted(() => {
  if (props.productItems.length > 0 && contentRef.value) {
    useScrollableReset(contentRef.value);
  }
});
</script>

<template>
  <div
    class="grid content-start grid-cols-3 gap-6 p-6 justify-items-center animate__animated animate__slideInLeft"
    :key="category.id"
  >
    <CatalogItem
      v-for="product in productItems"
      :key="product.id"
      :product="product"
      :price-decimals="priceDecimals"
      :allow-alcohol="alcohol.allow"
      :class="cardRadiusClass"
      @add-item="addItem(product)"
      @show-alcohol-confirm="openAlcoholConfirm(product)"
    />
  </div>

  <AlcoholConfirm
    v-if="showAlcoholConfirm"
    @cancel="denyAlcoholProducts()"
    @confirm="confirmProductSelection()"
  />
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: ease 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
