<script setup lang="ts">
import { goBackToHome } from '@/common/utils';

const route = useRoute();
const menuStore = useMenuStore();
const cartStore = useCartStoreV2();
const webhookStore = useWebhookStoreV2();
const inactiveStore = useInactiveStore();
const settingsStore = useSettingsStore();
const { triggerCancelIdleModal, triggerCancelOrder, triggerIdleModal, triggerStartOrder } = useGTMEventsComposableV2();

// const { items } = storeToRefs(cartStore);
const { gtmTransactionId } = storeToRefs(settingsStore);

/**
 * Reset the cart and redirect to first view
 */
function restartCart(isModal = false) {
  triggerCancelOrder({
    items: toGTMCartItems(cartStore.items),
    section: route.name!.toString(),
    isModal,
  });

  cartStore.clearCart();
  webhookStore.restartWebhook();

  if (settingsStore.multibrandData) {
    const { backUrl } = settingsStore.multibrandData;
    window.location.href = backUrl;
    return;
  }

  goBackToHome();
  // Reset transactionId
  gtmTransactionId.value = '';
}

function cancelInactivityModal() {
  inactiveStore.resetCountdown('timer');
  // Trigger GTM event
  triggerCancelIdleModal(route.name);
}

/**
 * Activate the inactive modal
 */
async function beforeMount() {
  const { storeId } = route.params;
  if (!storeId) return;

  await menuStore.getMenu(storeId as string);
  // Geneate transactionId
  gtmTransactionId.value = new Date().getTime().toString();
  triggerStartOrder();
  // Start countdown
  inactiveStore.resetCountdown('timer');
  inactiveStore.startCountdown();
}

function beforeUnmount() {
  restartCart();
}

onBeforeMount(() => beforeMount());
onBeforeUnmount(() => beforeUnmount());

watch(() => inactiveStore.timer, async (newValue) => {
  if (newValue <= 0) {
    inactiveStore.resetCountdown('screen');
    inactiveStore.startCountdown();
    // Trigger GTM event
    triggerIdleModal(route.name);
  }
});
</script>

<template>
  <router-view />

  <Teleport to="#inactive-modal">
    <OrderInactivityModal
      v-if="inactiveStore.currentKey === 'screen'"
      :time="inactiveStore.screen"
      @inactive="restartCart(true)"
      @close="cancelInactivityModal()"
    />
  </Teleport>
</template>
