<script lang="ts" setup>
import { between, integer, required } from '@vuelidate/validators';
import { useEventListener } from '@vueuse/core';
import { router } from '@/router';
import { RouteName } from '@/common/routes/routeName';

const showKeyboard = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);

const rules = {
  tableNumber: {
    required,
    integer,
    betweenValue: between(0, 100),
  },
};

const metadataStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const { tableNumber } = storeToRefs(orderTypeStore);
const { kioskSettings } = storeToRefs(metadataStore);
const v$ = useVuelidate(rules, { tableNumber });

useEventListener('paste', (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData?.getData('text');
  if (pastedData) {
    tableNumber.value = pastedData.replace(/\D/g, '');
  }
});

/**
 * Check if input receives an invalid value to prevent update
 * @param event - Keyboard event
 */
function updateTableInput(event: KeyboardEvent) {
  // Prevent double space
  if (event.code === 'Space') {
    event.preventDefault();
    return;
  }

  if (
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.code)
  ) {
    if (!Number.isFinite(+event.key)) event.preventDefault();
  }
}

/**
 * Calls router to redirect to Categories view.
 */
function goToCategories(skip = false) {
  if (!skip) orderTypeStore.setTableNumber(tableNumber.value);
  router.push({
    name: metadataStore.hideCategories
      ? RouteName.PRODUCTS
      : RouteName.ORDER_CATEGORIES,
  });
}

/**
 * Set 'tableNumber' value to null and
 * calls 'goToCategories' method.
 */
function skipTable() {
  orderTypeStore.setTableNumber('');
  // Make redirect
  goToCategories(true);
}

// TODO: Make unit test
watch(tableNumber, (newValue) => {
  if (newValue.includes('.')) {
    tableNumber.value = tableNumber.value.replace('.', '');
  }
});
</script>

<template>
  <span class="kfc-text text-[40px]">{{ $t("TABLE_NUMBER") }}</span>

  <div
    class="flex gap-2 flex-col justify-center bg-white items-center shadow-2xl rounded-xs p-10 w-[440px]"
  >
    <div class="flex items-center gap-5">
      <label for="tableNumber">
        <span class="icon icon-table-bar mr-5 text-[60px]" />
      </label>

      <input
        v-model="tableNumber"
        class="table-input"
        id="tableNumber"
        min="0"
        max="100"
        placeholder="00"
        maxlength="2"
        ref="inputRef"
        @keydown="updateTableInput($event)"
        @focus="showKeyboard = true"
      />
    </div>
    <small
      v-if="kioskSettings?.table.settings.qrScanner"
      class="text-lg text-gray-500 text-balance"
    >
      {{ $t("TABLE_HINT") }}
    </small>
  </div>

  <div class="flex flex-col items-center gap-10">
    <KioskButton
      :disabled="v$.$invalid"
      class="next-button"
      color="primary"
      @click="goToCategories()"
    >
      {{ $t("NEXT") }}
    </KioskButton>

    <div class="separator w-[346px] h-0.5 bg-neutral-400 my-4" />

    <KioskButton text-size="large" class="option-button" @click="skipTable()">
      {{ $t("PICK_UP_COUNTER") }}
    </KioskButton>
  </div>

  <SlideTransition enter-animation="fadeInUp" leave-animation="fadeOutDown">
    <NumbersKeyboard
      v-if="showKeyboard"
      class="absolute bottom-0 z-20 w-1/2 p-10"
      v-model="tableNumber"
      :input-ref="inputRef"
      :max-length="2"
      @close="showKeyboard = false"
    />
  </SlideTransition>
</template>
<style scoped>
.table-input {
  @apply bg-neutral-200 text-7xl text-center w-full focus:outline-none;
}

.next-button {
  @apply w-[420px] h-[120px] p-5 text-5xl flex items-center justify-center disabled:bg-opacity-30;
}

.option-button {
  @apply !min-w-[420px] max-w-[420px] !leading-[50px] text-5xl mb-5;
}
</style>
