<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { formatCurrency } from '@/common/utils';
import { selectedItems } from '@/utils/modifierSelectedItems';
import { StepWizardName } from '@/common/enums/stepWizardName';
import { Customer } from '../interfaces/customer';
import { CustomerItem } from '../interfaces/customerItem';

const { t } = useI18n();
const router = useRouter();
const cartStoreV2 = useCartStoreV2();
const { itemsCount } = storeToRefs(cartStoreV2);
const menuStore = useMenuStore();
const webhookStore = useWebhookStoreV2();
const metadataStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const { productsCurrency } = storeToRefs(menuStore);
const { customer, jobResponse, paymentInProgress } = storeToRefs(webhookStore);
const { orderType } = storeToRefs(orderTypeStore);
const {
  hideCash,
  hideCard,
  paymentImage,
  priceDecimals,
  clusterSettings,
  showClientType,
  timelineImage,
} = storeToRefs(metadataStore);
const { triggerGoBack } = useGTMEventsComposableV2();
const route = useRoute();
const { steps, stepIndex } = useStepWizard();
const { closeCurrencyModal, isOpenCurrencyModal, openCurrencyModal } = useCurrencyModal();
const couponStore = useCouponStore();

const hideSummary = ref(false);
const showUserInfo = ref(false);
const showBackActions = ref(true);

const orderTotal = computed((): Partial<HubsterOrderTotal> => {
  const { subtotal, taxes } = cartStoreV2;

  const total = subtotal - couponStore.discount;
  const subtotalWithoutTaxes = subtotal - taxes;

  return {
    subtotal: taxes === 0 ? subtotal : subtotalWithoutTaxes,
    tax: taxes,
    discount: couponStore.discount,
    total,
  };
});

const currencyValue = (value: number) =>
  formatCurrency(productsCurrency.value, value, priceDecimals.value);

const cartItems = computed(() => cartStoreV2.items.map((item) => item.item));

const userFields = computed((): Map<keyof Customer, CustomerItem> => {
  const values = new Map();
  const isNaturalPerson = customer.value.clientType === false;

  const fields: Partial<Record<keyof Customer, CustomerItem>> = {
    name: {
      label: t('USER_INFO.NAME'),
      type: 'text',
      icon: 'account',
      info: t('USER_INFO.VALIDATIONS.BILLING_NAMES_NEEDED'),
      placeholder: isNaturalPerson
        ? t('USER_INFO.NAME_PLACEHOLDER')
        : t('USER_INFO.COMPANY_NAME'),
    },
    taxIdentificationNumber: {
      label: t('USER_INFO.DOCUMENT_ID'),
      type: 'number',
      icon: 'citizen-id',
      info: t('USER_INFO.VALIDATIONS.BILLING_ID_NEEDED'),
      placeholder: t('USER_INFO.DOCUMENT_PLACEHOLDER'),
    },
    phone: {
      label: t('USER_INFO.PHONE'),
      type: 'number',
      icon: 'mobile',
      info: '',
      placeholder: t('USER_INFO.PHONE_PLACEHOLDER'),
    },
    email: {
      label: t('USER_INFO.EMAIL'),
      type: 'email',
      icon: 'email',
      info: t('USER_INFO.VALIDATIONS.BILLING_NEEDED'),
      placeholder: t('USER_INFO.EMAIL_PLACEHOLDER'),
    },
  };

  // Set default field
  values.set('name', fields.name);

  if (clusterSettings.value?.basics.customerInfo) {
    // Set values
    const { customerInfo } = clusterSettings.value.basics;
    const keys = Object.keys(customerInfo) as Array<keyof Customer>;

    keys.forEach((key) => {
      // @ts-ignore
      if (customerInfo[key] && fields[key]) values.set(key, fields[key]);
    });
  }

  return values;
});

/**
 * Create a payment order with specific payment method
 */
const { createOrder } = useCreateOrder();
function selectPaymentMethod(isCash: boolean) {
  createOrder(isCash ? PaymentMethod.CASH : PaymentMethod.CARD);
}

function createCashOrder() {
  createOrder(PaymentMethod.CASH);
}

function createOrderWithDifferentCurrency(currencyCode: string) {
  closeCurrencyModal();
  createOrder(PaymentMethod.CARD, currencyCode);
}

function createCardOrder() {
  if (!clusterSettings.value?.basics.pinpad.pinpadBrand) {
    createOrder(PaymentMethod.CARD);
    return;
  }

  const { conversions } = clusterSettings.value.currency;
  const { pinpadBrand } = clusterSettings.value.basics.pinpad;
  const isConversionEnabled = CLOUD_PINPADS.includes(pinpadBrand);
  if (isConversionEnabled && conversions?.length > 0) {
    openCurrencyModal();
    return;
  }

  createOrder(PaymentMethod.CARD);
}

function checkCustomerName() {
  if (customer.value.name.length >= 2) {
    showUserInfo.value = true;
    hideSummary.value = false;
  }
}

function goBack() {
  router.push({ name: 'CartV2' });
  triggerGoBack(route.name);
}

onBeforeMount(() => {
  if (showClientType.value) {
    customer.value.clientType = false;
  }
});

onUnmounted(() => {
  hideSummary.value = true;
});
</script>

<template>
  <section
    class="sticky top-0 z-20 flex items-center justify-center w-full pb-0 bg-neutral-200"
  >
    <div class="flex items-center w-full px-12 flex-nowrap">
      <KioskTimeline
        v-if="timelineImage"
        :steps="steps"
        :current-index="stepIndex(StepWizardName.PAY)"
        :active-image="timelineImage"
      />

      <KioskButton color="primary" class="flex-none !h-[68px] w-[108px] !py-2">
        <div class="flex items-center justify-center text-[32px] gap-1">
          <span class="icon icon-shopping-cart" />
          <span
            class="flex items-center justify-center p-1 text-black bg-white h-9 w-9 rounded-xs"
          >{{ itemsCount }}</span>
        </div>
      </KioskButton>
    </div>
  </section>

  <section class="flex flex-col justify-between h-[1690px] overflow-auto">
    <div class="options-container">
      <CollapsableContainer
        v-model="showUserInfo"
        :position="0"
        :title="t('CHECKOUT.CLIENT_INFO_TITLE')"
        @closed="checkCustomerName()"
      >
        <OrderCheckoutUserInfoV2
          v-model="customer"
          :show-client-type="showClientType"
          :user-fields="userFields"
          @confirm="checkCustomerName()"
        />
      </CollapsableContainer>

      <!-- Cart summary -->
      <CollapsableContainer
        v-model="hideSummary"
        :position="1"
        :title="t('CHECKOUT.SUMMARY_TITLE')"
        :disabled="!customer.name.length"
      >
        <section
          class="flex flex-col items-center justify-center pb-32 w-[600px] mx-auto mt-20 relative"
        >
          <div
            class="invoice-border bg-gradient-to-b from-primary-600 to-primary-800 w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14"
          >
            <div class="w-full h-20 p-4 dark-border bg-primary-900">
              <div class="w-full h-12 bg-black" />
            </div>
          </div>

          <div class="relative z-10 bg-white summary-card">
            <div class="flex flex-col gap-2">
              <h2 class="text-[32px] leading-6 tracking-tight font-bold">
                {{ customer.name }}
              </h2>
              <span class="order-type">{{ orderType }}</span>
            </div>

            <table class="max-h-[650px] overflow-scroll">
              <thead>
                <tr class="table-header">
                  <th class="text-start">
                    {{ $t("ITEMS") }}
                  </th>
                  <th class="text-center">
                    {{ $t("QUANTITY") }}
                  </th>
                  <th class="text-end">
                    {{ $t("PRICE") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <template :key="item.itemMeta.id" v-for="item in cartItems">
                  <tr class="table-columns">
                    <td class="pt-4 pb-2 text-start">
                      {{ item.itemMeta.name }}
                    </td>
                    <td class="pt-4 pb-2 text-center">
                      {{ item.quantity }}
                    </td>
                    <td class="pt-4 pb-2 text-end">
                      {{ currencyValue(item.itemMeta.price.amount) }}
                    </td>
                  </tr>

                  <OrderCheckoutRowV2
                    v-for="child in selectedItems(item.modifiers)"
                    :key="child.itemMeta.id"
                    :item="child"
                    :is-child-row="false"
                    :currency="productsCurrency"
                    :decimals="priceDecimals"
                  />
                </template>
              </tbody>
            </table>

            <div class="divider w-[575px] -bottom-7" />
          </div>

          <!-- PriceSummary -->
          <div
            class="flex flex-col overflow-hidden summary-total"
            :class="{ '!h-40': orderTotal.tax, '': orderTotal.discount }"
          >
            <template v-if="orderTotal.subtotal !== orderTotal.total">
              <div
                class="flex justify-between w-full h-8 font-normal text-neutral-400"
              >
                <span class="w-5/6 text-center">{{ t("SUBTOTAL") }}</span>
                <span>{{ currencyValue(orderTotal.subtotal!) }}</span>
              </div>
            </template>

            <template v-if="orderTotal.tax">
              <div
                class="flex justify-between w-full h-8 font-normal text-neutral-400"
              >
                <span class="w-5/6 text-center">{{ t("TAXES") }}</span>
                <span>{{ currencyValue(orderTotal.tax) }}</span>
              </div>
            </template>

            <template v-if="orderTotal.discount">
              <div
                class="flex justify-between w-full h-8 font-normal text-neutral-400"
              >
                <span class="w-5/6 text-center">{{ t("DISCOUNTS") }}</span>
                <span>-{{ currencyValue(orderTotal.discount) }}</span>
              </div>
            </template>

            <div class="flex justify-between w-full">
              <span class="w-5/6 text-center">{{ t("TOTAL") }}</span>
              <span>{{ currencyValue(orderTotal.total!) }}</span>
            </div>
            <div class="divider w-[590px] -bottom-[-6.25rem]" />
          </div>
        </section>

        <div class="payment-options">
          <div class="option" v-if="!hideCash">
            <span class="block my-6 text-4xl text-center">{{
              t("PAY_ON_SITE")
            }}</span>

            <KioskButton icon="cash" color="outline" @click="createCashOrder()">
              {{ t("CASH") }}
            </KioskButton>
          </div>

          <div class="option" v-if="!hideCard">
            <KioskImage
              v-if="paymentImage"
              :src="paymentImage"
              alt="Payment image"
              hide-border
              class="w-full mx-auto"
              loader-size="small"
            />

            <KioskButton
              icon="credit-card"
              color="primary"
              class="w-full mx-auto"
              @click="createCardOrder()"
            >
              {{ t("CARD") }}
            </KioskButton>
          </div>
        </div>
      </CollapsableContainer>
    </div>

    <SlideTransition leave-animation="fadeOut">
      <OrderBottomActions
        v-if="showBackActions"
        :back-label="$t('ORDER.BACK_CART')"
        :show-next-button="false"
        @back-pressed="goBack"
      />
    </SlideTransition>
  </section>

  <CurrencyModal
    v-if="isOpenCurrencyModal"
    @currency-selected="createOrderWithDifferentCurrency($event)"
    @close-modal="closeCurrencyModal()"
  />

  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="customer"
    :order-total="orderTotal"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>

<style scoped>
.options-container {
  @apply flex flex-col my-12 z-20 bg-white;
}

.options-container:last-child {
  @apply flex-grow;
}

.summary-card {
  @apply shadow-neutral-400/30 shadow-2xl;
  @apply p-12 border border-neutral-500/50 rounded-md rounded-t-none flex flex-col gap-8 w-[600px] min-h-[500px];
}

.order-type {
  @apply text-2xl leading-5 tracking-[0.5px];
}

.summary-total {
  @apply shadow-neutral-400/30 shadow-2xl z-20 bg-white border-b-0 py-3;
  @apply px-12 font-medium border flex justify-center items-center w-[600px] h-fit text-3xl border-t-0 border-neutral-500/50 rounded-t-md;
}

.header-item {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400;
}

.summary-line {
  @apply w-[556px] border;
}

.table-header > th {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400 pb-5;
}

.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}

.payment-options {
  @apply flex items-end justify-center w-full px-32 mt-24;
}

.payment-options .option {
  @apply flex flex-col justify-center w-1/2 mx-5;
}

.divider {
  @apply absolute z-40 left-1/2 -translate-x-1/2 border-t-8 border-spacing-0 border-neutral-500/80 border-dotted;
}

.user-checkbox {
  @apply w-7 h-7 mr-4;
}
</style>
