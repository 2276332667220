<script setup lang="ts">
const props = defineProps({
  bannerImage: {
    type: String,
    required: true,
  },
  intervalAction: {
    type: Function,
    required: true,
  },
});

const interval = ref<NodeJS.Timeout>();
const { intervalAction } = toRefs(props);

onMounted(() => {
  interval.value = setInterval(async () => { await intervalAction.value(); }, 30000);
});

onUnmounted(() => {
  clearInterval(interval.value);
});
</script>

<template>
  <div class="h-screen flex justify-center items-center">
    <KioskImage
      :src="bannerImage"
      alt="Maintenance image"
      hide-border
    />
  </div>
</template>

<style scoped>

</style>
