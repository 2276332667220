<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';

const router = useRouter();
const cartStore = useCartStoreV2();

defineProps({
  actionLbl: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['back', 'next']);

function goCart() {
  router.push({ name: RouteName.CART });
}
</script>

<template>
  <footer class="bottom-actions">
    <button
      type="button"
      class="text-4xl text-black uppercase h-max btn btn-ghost"
      @click="$emit('back')"
    >
      <img src="/src/assets/svg/home.svg" alt="home" />
    </button>

    <div class="flex items-center gap-10">
      <button :disabled="cartStore.itemsCount === '00'" @click="goCart()" type="button" class="cart-button">
        <span class="cart-counter">{{ cartStore.itemsCount }}</span>
        <img src="/src/assets/svg/bag.svg" alt="home" />
      </button>

      <KioskButton :disabled="disabled" @click="$emit('next')" class="checkout-button" text-size="medium" color="primary">
        {{ actionLbl }}
        <slot name="price" />
      </KioskButton>
    </div>
  </footer>
</template>
<style scoped>
.bottom-actions {
  @apply bg-white flex justify-between items-center gap-10 w-full px-8 py-3 z-20 relative;
  box-shadow: #0000009e -4px 4px 17px 0px;
}

.checkout-button {
  @apply h-[90px] flex gap-5;
}

.cart-button {
  @apply relative text-4xl text-black uppercase transition-all disabled:opacity-70 h-max btn btn-ghost disabled:bg-transparent
}

.cart-counter {
  @apply w-8 h-8 grid place-items-center absolute top-2 right-3 text-base rounded-full bg-kiosk-primary text-white;
}
</style>
