<script lang="ts" setup>
import { RouteName } from '@/common/routes/routeName';
import { LanguageLocales } from '@/modules/language/types';

const router = useRouter();
const menuStore = useMenuStore();
const settingsStore = useSettingsStore();
const metadataStore = useMetadataStore();
const languageStore = useLanguageStore();
const standbyBannerStore = useStandbyBannerStore();

const loading = ref(false);

const { gtmTransactionId } = storeToRefs(settingsStore);
const { banners, pausedBanner, startButton } = storeToRefs(metadataStore);
const { isLanguageEditable, languages } = storeToRefs(languageStore);

const showLoader = computed(() => !metadataStore.kioskSettings || !metadataStore.kioskSettings);

async function checkKioskStatus() {
  if (loading.value) return;
  loading.value = true;
  await settingsStore.getKioskStatus();
  loading.value = false;

  if (!settingsStore.isKioskDisabled) {
    router.push({ name: 'OrderOptions' });
  }
}

async function changeLanguage(lang: LanguageLocales) {
  languageStore.setLanguage(lang);
  await checkKioskStatus();
}

async function maintenanceAction(): Promise<void> {
  await settingsStore.getKioskStatus();

  if (!settingsStore.isKioskDisabled) {
    await settingsStore.fetchKioskMetadata();
    await settingsStore.refreshMetadata();
  }
}

async function clickBanner() {
  if (!isLanguageEditable.value) await checkKioskStatus();
}

/**
 * TODO: Migrate this logic into composable
 */
function beforeMount() {
  menuStore.reset();
  // Get data again
  gtmTransactionId.value = '';
  settingsStore.refreshMetadata();
}

// Set language with multi brand value
watch(() => languageStore.languages, (newData) => {
  if (newData && settingsStore.multibrandData) {
    const { language } = settingsStore.multibrandData;
    changeLanguage(language);
  }
});

onBeforeMount(() => beforeMount());

onBeforeRouteLeave((route) => {
  // Just preserve the checking of its close in standby banner and welcome view
  if (route.name !== RouteName.STAND_BY_BANNER) {
    standbyBannerStore.stopChecking();
  }
});
</script>

<template>
  <KioskLoading v-if="showLoader || loading" />

  <template v-else>
    <button
      class="relative full-hd"
      type="button"
      @click="clickBanner()"
      v-if="!pausedBanner"
    >
      <div
        v-if="banners?.length > 0"
        class="absolute top-0 z-0"
      >
        <ImageCarousel
          :data-images="banners"
          tailwind-classes="full-hd"
        />
      </div>

      <div class="menu-container">
        <template v-if="isLanguageEditable">
          <KioskButton
            v-for="languageItem in languages"
            :key="languageItem.id"
            class="menu-button"
            color="primary"
            text-size="large"
            @click="changeLanguage(languageItem.id)"
          >
            {{ languageItem.label }}
          </KioskButton>
        </template>

        <template v-if="!isLanguageEditable && startButton">
          <KioskButton
            class="menu-button"
            color="primary"
            text-size="large"
            @click="checkKioskStatus()"
          >
            {{ $t('ORDER_HERE') }}
          </KioskButton>
        </template>
      </div>
    </button>

    <MaintenanceBanner
      v-else
      :banner-image="pausedBanner"
      :interval-action="maintenanceAction"
    />
  </template>
</template>

<style scoped>
.menu-container {
  @apply flex gap-12 absolute bottom-56 w-full justify-center;
}
.menu-button {
  @apply min-w-[320px];
}
</style>
