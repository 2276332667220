import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { RouteName } from '../../../common/routes/routeName';

export function useRequestTableNumber() {
  const router = useRouter();
  const metadataStore = useMetadataStore();

  const { kioskSettings } = storeToRefs(metadataStore);
  const tableSettings = computed(() => kioskSettings.value?.table);

  const pickOrderType = (fulfillmentMode: FulfillmentMode) => {
    // if categories is disabled
    const redirectTo = metadataStore.hideCategories
      ? RouteName.PRODUCTS
      : RouteName.ORDER_CATEGORIES;

    if (!tableSettings.value?.enabled) {
      router.push({ name: redirectTo });
      return;
    }

    if (fulfillmentMode === FulfillmentMode.DINE_IN && tableSettings.value.settings.dineIn) {
      router.push({ name: RouteName.ORDER_TABLE });
      return;
    }

    if (fulfillmentMode === FulfillmentMode.PICKUP && tableSettings.value.settings.pickUp) {
      router.push({ name: RouteName.ORDER_TABLE });
      return;
    }

    router.push({ name: redirectTo });
  };

  return { pickOrderType };
}
