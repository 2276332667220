<script setup lang="ts">
import { IOrderOption } from '../types';

const metadaStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const requestTableNumber = useRequestTableNumber();

function setOption(option: IOrderOption) {
  orderTypeStore.setOrderTypeValue(option, metadaStore.hideTableNumber);
  requestTableNumber.pickOrderType(option.value);
}
</script>

<template>
  <span class="kfc-text text-[40px] leading-6">{{ $t('SELECT_ORDER_TYPE') }}</span>

  <div class="flex flex-col order-types">
    <KioskButton
      v-for="(option, index) in orderTypeStore.orderOptions"
      :key="index"
      :icon="option.icon"
      class="py-8 my-5"
      color="outline"
      text-size="large"
      @click="setOption(option)"
    >
      {{ option.label }}
    </KioskButton>
  </div>
</template>
