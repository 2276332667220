import { BrandSettingsDto } from '@slabcode/kiosks-core/dtos';

export function generateRootCss(settings: BrandSettingsDto): void {
  const root = document.documentElement;

  root.style.setProperty('--kiosk-primary', settings.colors.primary);
  root.style.setProperty('--kiosk-secondary', settings.colors.secondary);
  root.style.setProperty('--kiosk-tertiary', settings.colors.tertiary);
  root.style.setProperty('--kiosk-background', settings.colors.background ?? '#fff');
}
